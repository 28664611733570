import React from "react";
import Books from "./Books";
import CommonFooter from "../CommonFooter";

export default function Page() {
  return (
    <>
      <Books />
      <CommonFooter />
    </>
  );
}
