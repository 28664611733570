import React from "react";
import BookDetails from "./BookDetails";
import CommonFooter from "../CommonFooter";

export default function Page() {
  return (
    <>
      <BookDetails />
      <CommonFooter />
    </>
  );
}
